import { render, staticRenderFns } from "./RoleBase.vue?vue&type=template&id=985ed8ca"
import script from "./RoleBase.vue?vue&type=script&lang=ts&setup=true"
export * from "./RoleBase.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('985ed8ca')) {
      api.createRecord('985ed8ca', component.options)
    } else {
      api.reload('985ed8ca', component.options)
    }
    module.hot.accept("./RoleBase.vue?vue&type=template&id=985ed8ca", function () {
      api.rerender('985ed8ca', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/iam/RoleBase.vue"
export default component.exports